<template>
  <standard-page :definition="$DEFINITIONS.provenance.processSteps" :status="process.status" :sub_title="'Process Steps'" :title="process.name" v-if="process">
    <template v-if="currentRouteName === 'process-steps'" v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :to="{ name: 'process-definition-steps'}">Process Definition:: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'process'}">Processes</router-link>
      </span>
      <span v-if="process">Process: {{process.name | truncate(10, '.....')}}</span>
    </template>
    <template v-else v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span v-if="process.status=='Completed'">
        <router-link :to="{ name: 'complete-process'}">Completed Processes</router-link>
      </span>
      <span v-else>
        <router-link :to="{ name: 'ongoing-process'}">Ongoing Processes</router-link>
      </span>
      <span v-if="process">
        <router-link :to="{ name: 'process-definition-steps'}">Process: {{process.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="process">Process: {{process.name | truncate(10, '.....')}}</span>

    </template>
    <template v-slot:back-breadcrumb>
      <span v-if="process.status=='Completed'">
        <router-link :to="{ name: 'complete-process'}"><i class="fa fa-arrow-left" />Back</router-link>
      </span>
      <span v-else>
        <router-link :to="{ name: 'ongoing-process'}"><i class="fa fa-arrow-left" />Back</router-link>
      </span>
    </template>
    <template v-if="!is_order_0_attested && is_owner" v-slot:buttons>
      <button @click="deleteProcess" class="d-none d-xl-block float-right btn btn-danger rounded font-weight-bold" title="Delete Process">
        <i class="fas fa-trash-alt mg-r-10" />Delete Process
      </button>
        <button @click="deleteProcess" class="d-xl-none float-right w-35 btn btn-icon btn-danger rounded" title="Delete Process">
            <i class="typcn typcn-trash" />
        </button>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" :server-side="true" list-key="process_steps" total-key="total">
      </data-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "ProcessSteps",
  props: ['registryid', 'processdefinitionid', 'processid'],
  computed: {
    ...mapGetters(["currentUser"]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps`,
      columns: [
        { type: 'order' },
        { type: 'name' },
        { type: 'provenanceProcessStatus' },
        { type: 'attestor' },
        { type: 'attributes' },
        { type: 'attested' },
        { type: 'extrinsic' },
      ],
      table: null,
      step_to_attest: null,
      process: null,
      is_owner: false,
      is_order_0_attested: true,
      registry: null,
      processDefinition: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getProcess();
      await this.getFirstProcessStep();
      if (this.currentRouteName === 'process-steps') {
        await this.getRegistry();
        await this.getProcessDefinition();
      }
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    async getProcess() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
        this.process = data;
        this.is_owner = this.process.created_by == this.currentUser.public_key;
      } catch (e) {

      } finally {

      }
    },
    async getFirstProcessStep() {
      this.is_order_0_attested = true;
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps?page=0&per_page=1&sort_by=order&order=ASC`);
        let order_0_step = data ? data.process_steps[0] : null;
        if (order_0_step) {
          this.is_order_0_attested = Object.values(order_0_step).includes("Attested");
        }
      } catch (e) {
        console.error(e);
      } finally {

      }
    },
    async deleteProcess() {
      try {
        EventBus.$emit('openLoader');
        await this.$provenance.delete(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
        this.$toastr.s(`Process Successfully deleted!`, 'Success');
      } catch (e) {
        console.log(e);
        this.$toastr.e(`Process deletion failed`, 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    addProcessStep() {
      this.$router.push({ name: 'new-process-steps' });
    },
    view(process) {
      console.log(process);
      if (this.currentRouteName === 'process-steps' && process.process_step) {
        console.log("inside 1");
        this.$router.push({ name: 'view-process-step', params: { processdefinitionstepid: process.process_definition_step, processstepid: process.process_step } });
      } else if (this.currentRouteName === 'process-steps' && !process.process_step) {
        console.log("inside 2");
        this.$router.push({ name: 'view-future-process-step', params: { processdefinitionstepid: process.process_definition_step, processid: this.processid } });
      } else if (process.status === 'Attested') {
        console.log("inside 3");
        this.$router.push({ name: 'view-complete-process-step', params: { processdefinitionstepid: process.process_definition_step, processstepid: process.process_step } });
      } else if (process.process_step) {
        console.log("inside 4");
        this.$router.push({ name: 'view-ongoing-process-step', params: { processdefinitionstepid: process.process_definition_step, processstepid: process.process_step } });
      } else {
        console.log("inside 5");
        this.$router.push({ name: 'view-future-ongoing-process-step', params: { processdefinitionstepid: process.process_definition_step } });
      }
    }
  }
}
</script>

<style scoped>
</style>